export class RedIfNegativeCustomAttribute {
    static inject = [Element];
    element;

    constructor(element) {
        this.element = element;
    }

    attached() {
        this._initialize();
    }

    valueChanged() {
        this._initialize();
    }

    _initialize() {
        try {
            if (!this.element || this.value === undefined) return;
            this.element.classList.remove('lpfn-error');
            const numberValue = parseFloat(this.value);
            if (numberValue < 0) this.element.classList.add('lpfn-error');
        } catch (err) {
            console.log(err);
        }
    }
}
