import { EventAggregator } from 'aurelia-event-aggregator';
import { Security } from 'common/security';
import { c } from 'common/common';

export class RequireSecurityCustomAttribute {
    static inject = [Element, EventAggregator, Security];
    element;
    _ea;
    _security;
    _originalDisplay = null;

    constructor(element, ea, security) {
        this.element = element;
        this._ea = ea;
        this._security = security;
        this._ea.subscribe(c.EventKeys.loginAuthenticationUpdated, () => this._setDisplay());
    }

    bind() {
        this._setDisplay();
    }

    valueChanged() {
        this._setDisplay();
    }

    _setDisplay() {
        if (!this._security.isAuthenticated) {
            if (this._originalDisplay !== null) {
                this.element.style.display = this._originalDisplay;
                this._originalDisplay = null;
            }
            return;
        }

        if (this.value && !this._security[this.value]) {
            if (this._originalDisplay === null) this._originalDisplay = this.element.style.display;
            this.element.style.display = 'none';
        } else {
            if (this._originalDisplay !== null) {
                this.element.style.display = this._originalDisplay;
                this._originalDisplay = null;
            }
        }
    }
}
