import { toDoType } from 'services/to-dos';
import { ToDoTypeValueConverter } from 'resources/value-converters/to-do-type';

export class ToDoIconCustomAttribute {
    static inject = [Element, ToDoTypeValueConverter];
    element;
    _vc;

    constructor(element, vc) {
        this.element = element;
        this._vc = vc;
    }

    attached() {
        this._initialize();
    }

    typeChanged() {
        this._initialize();
    }

    _clear() {
        if (!this.element) return;
        this.element.classList.remove('fa-duotone');
        this.element.classList.remove('fa-file-contract');
        this.element.classList.remove('fa-filter-circle-dollar');
        this.element.classList.remove('fa-user');
        this.element.classList.remove('fa-bell-on');
    }

    _initialize() {
        try {
            if (!this.element || this.value === undefined) return;
            this._clear();
            this.element.classList.add('fa-duotone');
            this.element.setAttribute('title', this._vc.toView(this.value));
            switch (this.value) {
                case toDoType.LEAD:
                    this.element.classList.add('fa-filter-circle-dollar');
                    break;
                case toDoType.AGENT:
                    this.element.classList.add('fa-user');
                    break;
                case toDoType.POLICY:
                    this.element.classList.add('fa-file-contract');
                    break;
                default:
                    this.element.classList.add('fa-bell-on');
                    break;
            }
        } catch (err) {
            console.log(err);
        }
    }
}
