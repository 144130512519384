import { bindable } from 'aurelia-framework';

export class HoverIntentCustomAttribute {
    static inject = [Element];
    @bindable({ primaryProperty: true }) interval = 400;
    element;

    constructor(element) {
        this.element = element;
    }

    attached() {
        this._initialize();
    }

    _initialize() {
        try {
            $(this.element).hoverIntent({
                interval: this.interval,
                timeout: 100,
                over: () => {
                    this.element.dispatchEvent(new CustomEvent('hoverintentover', { bubbles: true, detail: {} }));
                },
                out: () => {
                    this.element.dispatchEvent(new CustomEvent('hoverintentout', { bubbles: true, detail: {} }));
                },
            });
        } catch (err) {
            console.log(err);
        }
    }
}
