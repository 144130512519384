export class ImageOnErrorCustomAttribute {
    static inject = [Element];
    element;

    constructor(element) {
        this.element = element;
    }

    attached() {
        try {
            if (!this.element) return;
            const errorSrc = this.value || '/memberzone/profile/default-profile.jpg';
            this.element.setAttribute('onerror', `this.src='${errorSrc}'`);
        } catch (err) {
            console.log(err);
        }
    }
}
