import { PLATFORM } from 'aurelia-pal';

const attributeGlobalResources = [
    PLATFORM.moduleName('resources/attributes/colorize-ryg'),
    PLATFORM.moduleName('resources/attributes/gs-h'),
    PLATFORM.moduleName('resources/attributes/gs-w'),
    PLATFORM.moduleName('resources/attributes/hover-intent'),
    PLATFORM.moduleName('resources/attributes/image-on-error'),
    PLATFORM.moduleName('resources/attributes/red-if-negative'),
    PLATFORM.moduleName('resources/attributes/require-agency'),
    PLATFORM.moduleName('resources/attributes/require-is-authenticated'),
    PLATFORM.moduleName('resources/attributes/require-level'),
    PLATFORM.moduleName('resources/attributes/require-minimum-level'),
    PLATFORM.moduleName('resources/attributes/require-not-role'),
    PLATFORM.moduleName('resources/attributes/require-not-security'),
    PLATFORM.moduleName('resources/attributes/require-role'),
    PLATFORM.moduleName('resources/attributes/require-security'),
    PLATFORM.moduleName('resources/attributes/to-do-icon'),
];

export default attributeGlobalResources;