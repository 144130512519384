export class GsHCustomAttribute {
    static inject = [Element];
    _element;

    constructor(element) {
        this._element = element;
    }

    valueChanged() {
        this._element.setAttribute('gs-h', this.value);
    }
}
