import { bindable } from 'aurelia-framework';

export class ColorizeRygCustomAttribute {
    static inject = [Element];
    @bindable color;
    @bindable type = 'bg';
    @bindable breaks;
    @bindable value;
    @bindable reversed = false;
    element;

    constructor(element) {
        this.element = element;
    }

    attached() {
        this._initialize();
    }

    colorChanged() {
        if (!this.color) return;
        this._initialize();
    }

    valueChanged() {
        if (this.value === undefined || this.value === null) {
            this._clear();
            return;
        }
        this._initialize();
    }

    _clear() {
        if (!this.element) return;
        this.element.classList.remove(`lpfn-colorized-bg`);
        this.element.classList.remove(`lpfn-colorized-color`);
        this.element.classList.remove(`lpfn-colorized-border`);
        this.element.classList.remove('lpfn-ryg-r');
        this.element.classList.remove('lpfn-ryg-y');
        this.element.classList.remove('lpfn-ryg-g');
    }

    _initialize() {
        try {
            if (!this.element || this.value === undefined) return;
            this._clear();
            this.element.classList.add(`lpfn-colorized-${this.type}`);
            if (this.color) {
                this.element.classList.add(`lpfn-ryg-${this.color}`);
                return;
            }
            if (!this.reversed) {
                if (this.value < this.breaks[0]) this.element.classList.add('lpfn-ryg-r');
                else if (this.value < this.breaks[1]) this.element.classList.add('lpfn-ryg-y');
                else this.element.classList.add('lpfn-ryg-g');
            } else {
                if (this.value > this.breaks[0]) this.element.classList.add('lpfn-ryg-g');
                else if (this.value > this.breaks[1]) this.element.classList.add('lpfn-ryg-y');
                else this.element.classList.add('lpfn-ryg-r');
            }
        } catch (err) {
            console.log(err);
        }
    }
}
